import * as React from 'react';

import Header from './components/header';
import Footer from './components/footer';

import Quote from './components/quote';
import Test from './components/test';

// markup
const IndexPage = () => {
	return (
		<>
			<Header />
			<Quote />
			<Footer />
		</>
	);
};

export default IndexPage;
